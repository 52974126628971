import React from "react"
import { Container } from "react-bootstrap"
import { Parallax } from "react-scroll-parallax"
import Image from "gatsby-image"
import InternalLink from "../helper/internalLink"

const PageHeader = ({titleColored, title, description, btnLabel, btnTarget, img, lang}) => (
  <section className="component pageHeader bg-white py-5">
    <Container fluid>
        <div className="row align-items-center">
            <div className="col-12 col-md-6 mt-5 mb-mt-0 order-2 order-md-1">
            <Parallax className="custom-class" y={[20, -20]} tagOuter="figure">
              {(img.fluid) && <Image fluid={img.fluid} className="img-fluid" />}
              {img.file && <img src={img.file.url} className="img-fluid" alt="preview" />}
            </Parallax>
            </div>
            <div className="col-12 col-md-6 order-1 order-md-2">
                <h1 className="mb-4"><span>{titleColored}</span> {title}</h1>
                <p>{description}</p>
                {btnTarget && <InternalLink btnTarget={btnTarget} className="btn mt-3" btnLabel={btnLabel} lang={lang} />}
            </div>
        </div>
    </Container>
  </section>
)

export default PageHeader