import React from 'react'
import ContentTeaser from './contentTeaser'
import GeneralContent from './generalContent'
import PageHeader from './pageHeader'
import FourTeaser from './fourTeaser'
import Masony from './masonry'
// 
// Live Content Handling
// 

export const renderDynamicComponents = (components, lang) => {

    return components.map((component, index) => {
        switch (component.internal.type) {
            case 'ContentfulComponentGeneralContent':
                return (
                    <GeneralContent
                        key={index}
                        additionalClass=""
                        title={component.title}
                        content={component.content.json}
                        lang={lang}
                    />
                )
            case 'ContentfulComponentReferenceContainer':
                return (
                    <Masony
                        references={component.references}
                        additionalClass=""
                    />
                )    
            case 'ContentfulComponentContentTeaser':
                return (
                    <ContentTeaser
                        key={index}
                        additionalClass=""
                        category={component.subline}
                        title={component.title}
                        description={component.content.json}
                        btnTarget={component.link ? component.link.slug : null}
                        btnLabel={component.linkText}
                        img={component.teaserImage}
                        lang={lang}
                    />
                )
            case 'ContentfulComponentPageHeader':
                return (
                    <PageHeader
                        key={index}
                        titleColored={component.titleColored}
                        title={component.title}
                        description={component.text}
                        btnTarget={component.link ? component.link.slug : null}
                        btnLabel={component.linkText}
                        img={component.headerImage}
                        lang={lang}
                    />
                )
            case 'ContentfulComponentFourTeaser':
                return (
                    <FourTeaser
                        key={index}
                        title1={component.title1}
                        description1={component.content1}
                        title2={component.title2}
                        description2={component.content2}
                        title3={component.title3}
                        description3={component.content3}
                        title4={component.title4}
                        description4={component.content4}
                        lang={lang}
                    />
                )
            default:
                return null
        }
    })
}

// 
// Preview Content Handling
// 

export const renderPreviewComponents = (components, lang) => {
    return components.map((component, index) => {
        switch (component.sys.contentType.sys.id) {
            case 'generalContent':
                return (
                    <GeneralContent
                        key={index}
                        additionalClass=""
                        title={component.fields.title}
                        content={component.fields.content}
                        lang={lang}
                    />
                )
            case 'componentContentTeaser':
                return (
                    <ContentTeaser
                        key={index}
                        additionalClass="ml-minus"
                        category={component.fields.subline}
                        title={component.fields.title}
                        description={component.fields.content}
                        btnTarget={component.fields.link.sys.id}
                        btnLabel={component.fields.linkText}
                        img={component.fields.teaserImage.fields}
                        lang={lang}
                    />
                )
            case 'componentPageHeader':
                return (
                    <PageHeader
                        key={index}
                        titleColored={component.titleColored}
                        title={component.fields.title}
                        description={component.fields.text}
                        btnTarget={component.fields.link.sys.id}
                        btnLabel={component.fields.linkText}
                        img={component.fields.headerImage.fields}
                        lang={lang}
                    />
                )
            case 'componentFourTeaser':
                return (
                    <FourTeaser
                        key={index}
                        title1={component.fields.title1}
                        description1={component.fields.content1}
                        title2={component.fields.title2}
                        description2={component.fields.content2}
                        title3={component.fields.title3}
                        description3={component.fields.content3}
                        title4={component.fields.title4}
                        description4={component.fields.content4}
                        lang={lang}
                    />
                )
            default:
                return null
        }
    })
}