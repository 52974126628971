import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types';

const richTextRenderer = (document, lang) => {
    const richTextOptions = {
        renderNode: {
            [BLOCKS.EMBEDDED_ASSET]: (node) => {
                const { title, description, file } = node.data.target.fields;
                const imageFile = file[lang] ? file[lang] : file
                const mimeType = imageFile.contentType
                const mimeGroup = mimeType.split('/')[0]

                switch (mimeGroup) {
                    case 'image':
                        return (
                            <img
                                title={ title ? title[lang] : null}
                                alt={description ?  description[lang] : null}
                                src={imageFile.url}
                                className="img-fluid"
                            />
                        )
                    default:
                        return null
                }
            }
        }
    }
    return documentToReactComponents(document, richTextOptions)
}

export default richTextRenderer