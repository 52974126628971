/* Global imports */
import React, { useEffect, useState } from 'react'
import Masonry from 'react-masonry-css'
import { Container } from "react-bootstrap"

/* Local imports */
import useWindowSize from '../hooks/useWindowSize'

const breakpointColumnsObj = {
  default: 2,
  1000: 2,
  700: 1,
};

/* Component definition */
const MasonryGrid = ({ references, additionalClass }) => {
  const size = useWindowSize()
  const [active, setActive] = useState(null)

  useEffect(() => {
    if(size.width < 450) {
      let elements = []
      references.map(reference => elements.push(document.getElementById(reference.id)))

      const onScroll = () => {
        elements.map(el => {
          const isElementVisible = el.getBoundingClientRect().top > 130 && el.getBoundingClientRect().top < 300 
  
          if (isElementVisible) {
            setActive(el.id)
          }
        })
      }

      window.addEventListener('scroll', onScroll)
    }
  })

  return (
    <section className={`component reference-container my-md-0 ${additionalClass}`}>
      <Container fluid>
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="masonry-grid"
          columnClassName="masonry-grid-column"
        >
          {
            references
              .map((item, index) => (
              <div className="item-wrapper" key={index} id={item.id}>
                <img src={item.headerImage.fluid.src} className="masonry-image" alt={item.title}/>
                <div className={`overlay-text ${active === item.id && size.width < 450 ? 'active' : null}`}>
                    {
                      item.company &&
                      <div className="case-company">{item.company}</div>
                    }
                    <div className="case-title">{item.title}</div>
                  <div className="case-description">{item.shortDescription}</div>
                </div>
              </div>
            ))
          }
        </Masonry>
      </Container>
    </section>
  )
}
/* PropTypes */
MasonryGrid.propTypes = {}
MasonryGrid.defaultProps = {}


/* Local u
tility functions */

/* Styles */

export default MasonryGrid
