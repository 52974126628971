import React from "react"
import { Container } from "react-bootstrap"
import { ParallaxBanner } from 'react-scroll-parallax'
import bg from "../images/bg3.jpg"
import icon1 from "../images/feature-icons/service-icon-1.png"
import icon2 from "../images/feature-icons/service-icon-2.png"
import icon3 from "../images/feature-icons/service-icon-3.png"
import icon4 from "../images/feature-icons/service-icon-4.png"

const FourTeaser = ({title1, description1, title2, description2, title3, description3, title4, description4}) => (
  <section className="component fourTeaser py-5 py-md-0">
      <ParallaxBanner
        className="align-items-center d-flex"
        layers={[
            {
                image: bg,
                amount: 0.4,
            }
        ]}
        style={{
            height: '500px',
        }}
      >
        <Container fluid >
          <div className="row items-wrapper">
            <div className="col-12 col-md-3 item text-center mb-5 mb-md-0">
              <img src={icon1} alt="" />
              <h2 className="h4 mt-4 mb-4">{title1}</h2>
              <p>{description1}</p>
            </div>
            <div className="col-12 col-md-3 item text-center mb-5 mb-md-0">
              <img src={icon2} alt="" />
              <h2 className="h4 mt-4 mb-4">{title2}</h2>
              <p>{description2}</p>
            </div>
            <div className="col-12 col-md-3 item text-center mb-5 mb-md-0">
              <img src={icon3} alt="" />
              <h2 className="h4 mt-4 mb-4">{title3}</h2>
              <p>{description3}</p>
            </div>
            <div className="col-12 col-md-3 item text-center mb-5 mb-md-0">
              <img src={icon4} alt="" />
              <h2 className="h4 mt-4 mb-4">{title4}</h2>
              <p>{description4}</p>
            </div>
          </div>
        </Container>
      </ParallaxBanner>
  </section>
)

export default FourTeaser