import React from "react"
import { Container } from "react-bootstrap"
import richTextRenderer from '../helper/rich-text-renderer'
import InternalLink from "../helper/internalLink"

const ContentTeaser = ({ title, description, btnLabel, img, additionalClass, category, btnTarget, lang }) => (
  <section className={`component contentTeaser py-5 my-5 my-md-0 ${additionalClass}`}>
    <Container fluid>
      <div className="row align-items-center">
        <div className="col-12 col-md-4 d-none d-md-block">
          { img && img.fluid && <img src={img.fluid.src} className="img-fluid" />}
          {img && img.file && <img src={img.file.url} className="img-fluid" alt="preview" />}
        </div>
        <div className="col-12 col-md-8">
          <span className="category">{category}</span>
          <h2 className="h3 mt-3 mb-4">{title}</h2>
          {richTextRenderer(description, lang)}
          {btnTarget && <InternalLink btnTarget={btnTarget} className="mt-4 d-block" btnLabel={btnLabel} lang={lang} />}
        </div>
      </div>
    </Container>
  </section>
)

export default ContentTeaser