import React from "react"
import { Container } from "react-bootstrap"
import richTextRenderer from '../helper/rich-text-renderer'

const GeneralContent = ({ title, content, additionalClass, lang }) => (
  <section className={`component generalContent py-3 my-5 my-md-0 ${additionalClass}`}>
    <Container fluid>
      <div className="row align-items-center">
        <div className="col-12">
          <h2 className="h3 mb-4">{title}</h2>
          {richTextRenderer(content, lang)}
        </div>
      </div>
    </Container>
  </section>
)

export default GeneralContent